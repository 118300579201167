import { useContext, useEffect } from 'react';
import Form from '../../components/Form/Form';
import Gallery from '../../components/Gallery/Gallery';
import Tabs from '../../components/Tabs/Tabs';
import DataContext from '../../context/DataContext';
import './style.scss';

export default function Coworking({ text, openLang }) {
  const { setLinkEN, setLinkUA, setLang } = useContext(DataContext);
  useEffect(() => {
    setLinkEN('/en/coworking');
    setLinkUA('/coworking');
    setLang(openLang);
  }, []);
  return (
    <main>
      <div className="bg bg-5"></div>
      <header className="header header-home header-capsule container">
        <div className="header-overlay gradient"></div>
        <h1>
         <b> {text.head.name} <br />
          {text.head.title}</b>
        </h1>
        <p>{text.head.desc}</p>
        
        <div className="head_row">

          <div className="head_benefits">
            {text.head.benefits.map((el, index) => (
              <div
                key={index}
                className={`head_benefits_item head_benefits_${index + 1}`}
              >
                <span className="title">{el}</span>
              </div>
            ))}
          </div>

          <div className="head_price">
            <span>{text.head.priceFrom}</span><br />
            <p className="price">{text.head.price}<sup>{text.head.currency}</sup></p>
            
          </div>
        </div>
        <Form name="order_about" />
      </header>
      <div className="description container">
        <h2>{text.description.title}</h2>
        <Tabs text={text} imgClass="coworking_stuff" />
        <div className="description_more">
          <h2>{text.description.more_title}</h2>
          <p>{text.description.more_text}</p>
        </div>
      </div>
      <div className="gallery_wrapper container">
        <h2>{text.gallery.title}</h2>
        <p>{text.gallery.desc}</p>
        <Gallery name="gallery_coworking" />
      </div>
    </main>
  );
}
