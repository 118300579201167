import { useContext, useEffect } from 'react';
import DataContext from '../../context/DataContext';
import './style.scss';

export default function Franchise({ text, openLang }) {
    const { setLinkEN, setLinkUA, setLang } = useContext(DataContext);
  useEffect(() => {
    setLinkEN('/en/franchise');
    setLinkUA('/franchise');
    setLang(openLang);
  }, []);
  return (
    <main>
      <div className="bg bg-footer"></div>
      <header className="header header-franchise container">
        <div className="header-overlay gradient"></div>
        <h1>{text.head.title}</h1>
        <div className="order_wrapper order_about"></div>
      </header>
      <div className="franchise container">
        <h2>{text.text}</h2>
        <div className="franchise_contacts">
            <a href="tel:">+38 044 555 22 90</a>
            <a href="mailto:">mailsample@mail.com</a>
        </div>
      </div>
    </main>
  );
}
