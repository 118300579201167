import React, { useContext, useEffect, useState } from 'react'
import DataContext from '../../context/DataContext';

export default function BookingEn({openLang}) {
    const { lang, setLinkEN, setLinkUA, setLang } = useContext(DataContext);
    const [test, settest] = useState(1)
    useEffect(() => {
        settest(prev=>prev+1);
        if(test===4) {
            window.location.reload()

        }
        setLinkEN(`/en/booking/${window.location.search}`);
        setLinkUA(`/booking/${window.location.search}`);
        setLang(openLang);
      }, []);
  return (
    <main className="booking_wrapper">
      <div className="bg bg-footer"></div>
      <div id="tl-booking-form-en" className="tl-container"></div>
    </main>
  )
}
