import React, { useState } from 'react'
import './style.scss'

export default function Slider({photoClass}) {
    const [photo, setPhoto] = useState(1)
  return (
    <div className={`img_block ${photoClass}`}>
        <div className={`img_active img_${photo}`}>

        </div>
        <div className='img_list'>
            <div onClick={()=>setPhoto(1)} className="img img_1"></div>
            <div onClick={()=>setPhoto(2)} className="img img_2"></div>
            <div onClick={()=>setPhoto(3)} className="img img_3"></div>
            <div onClick={()=>setPhoto(4)} className="img img_4"></div>
        </div>
    </div>
  )
}
