import './style.scss';
import { useContext, useEffect } from 'react';
import DataContext from '../../context/DataContext';
import Faq from '../../components/Faq/Faq';
import Form from '../../components/Form/Form';

export default function About({ text, openLang }) {
  const { setLinkEN, setLinkUA, setLang } = useContext(DataContext);
  useEffect(() => {
    setLinkEN('/en/about');
    setLinkUA('/about');
    setLang(openLang);
  }, []);

  return (
    <main>
      <div className="bg bg-4"></div>
      <div className="bg bg-footer"></div>
      <header className="header header-about container">
        <div className="header-overlay gradient"></div>
        <h1>{text.head.title}</h1>
        <Form name='order_about'/>
      </header>

      <div className="concept_wrapper container">
        <h2>{text.concept.title}</h2>
        <div className="concept">
          <div className="concept_left"></div>
          <div className="concept_right">
            <h3>{text.concept.inner_title}</h3>
            <p>{text.concept.desc}</p>
            <div className="concept_benefits">
              {text.concept.benefits.map((el, index) => (
                <div key={index} className="concept_benefits_item">
                  <h4>{el}</h4>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="pain_wrapper container">
        <h2>{text.pain.title}</h2>
        <div className="pain_list">
          {text.pain.list.map((el,index)=>(
            <div className="pain_list_item" key={index}>
              <h3>{el.title}</h3>
              <p>{el.desc}</p>
            </div>
          ))}
        </div>
      </div>
    <div className="pain_offer container">
            <h2>{text.faq.title}</h2>
      <Faq arr={text.faq.list}/>
    </div>
    </main>
  );
}
