import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import DataContext from '../../context/DataContext';
import './style.scss';

export default function Nav({ text }) {
  const [showLangPopup, setShowLangPopup] = useState(false);
  const [showPhonePopup, setShowPhonePopup] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const {pathname} = useLocation();
  useEffect(()=>{
    setShowMenu(false)
  }, [pathname])

  const sublink = () => {
    if (lang === 'ua') {
      return '';
    } else {
      return lang;
    }
  };

  // document.addEventListener('mousedown', (event) => {
  //   if (!document.querySelector('.lang_wrapper').contains(event.target)) {
  //     setShowLangPopup(false);
  //   }
  // });
  document.addEventListener('mousedown', (event) => {
    if (!document.querySelector('.phone_wrapper').contains(event.target)) {
      setShowPhonePopup(false);
    }
  });
  
  document.addEventListener('mousedown', (event) => {
    if (!document.querySelector('.menu_wrapper').contains(event.target)) {
      setShowMenu(false);
    }
  });

  const { lang, setLang, linkEN, linkUA } = useContext(DataContext);
  return (
    <nav className="nav">
      <div className="container">
        <div onClick={()=>setShowMenu(!showMenu)} className="menu_open"></div>
        <NavLink end to={`${sublink()}/`} className="logo">
          {' '}
        </NavLink>
        <div className={showMenu?"menu_wrapper active":"menu_wrapper"}>
          <div className="nav_menu">
            <NavLink
              end
              className={({ isActive }) =>
                isActive ? 'navlink active' : 'navlink'
              }
              to={`${sublink()}/`}
            >
              {text[lang].menu.home}
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? 'navlink active' : 'navlink'
              }
              to={`${sublink()}/about`}
            >
              {text[lang].menu.about}
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? 'navlink active' : 'navlink'
              }
              to={`${sublink()}/rooms`}
            >
              {text[lang].menu.rooms}
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? 'navlink active' : 'navlink'
              }
              to={`${sublink()}/capsules`}
            >
              {text[lang].menu.capsules}
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? 'navlink active' : 'navlink'
              }
              to={`${sublink()}/coworking`}
            >
              {text[lang].menu.coworking}
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? 'navlink active' : 'navlink'
              }
              to={`${sublink()}/contacts`}
            >
              {text[lang].menu.contacts}
            </NavLink>
          </div>
          <div onClick={()=>setShowMenu(!showMenu)} className="menu_close"></div>
          <div className="mobile_contacts">
            <p>{text[lang].menu.phone}</p>
            <a href="tel:+380936020900">+38 093 602 09 00</a>
            <br />
            <a href="tel:+380930075046">+38 093 007 50 46</a>
          </div>
        </div>
        <div className="nav_buttons">
          <div className="buttons_wapper phone_wrapper">
            <span
              onClick={() => setShowPhonePopup(!showPhonePopup)}
              className="phone"
            ></span>
            <div
              className={
                showPhonePopup
                  ? 'lang_dropdown phone_dropdown active'
                  : 'lang_dropdown phone_dropdown'
              }
            >
              <a href="tel:+38 044 555 22 90" className="lang_item">
                +38 044 233 22 90
              </a>
              <a href="tel:+38 044 555 22 90" className="lang_item">
                +38 044 555 45 40
              </a>
              <a href="tel:+38 044 555 22 90" className="lang_item">
                +38 044 531 15 43
              </a>
            </div>
          </div>

          <div className="buttons_wapper buttons_social">
            <a className="inst" target='_blank' rel="noreferrer" href="https://www.instagram.com/kyivhostel.space/?igshid=ZDdkNTZiNTM%3D">
              {' '}
            </a>
            <a className="tg" target='_blank' rel="noreferrer" href="tg://resolve?domain=KyivHostel">
              {' '}
            </a>
          </div>

          {/* {window.location.href.includes('booking')?<></>:<div className="buttons_wapper lang_wrapper">
            <div
              className="lang_current"
              onClick={() => setShowLangPopup(!showLangPopup)}
            >
              <div className={`ico_lang ico_${lang}`}></div>
            </div>

            <div
              className={
                showLangPopup ? 'lang_dropdown active' : 'lang_dropdown'
              }
            >
              <NavLink
                className="lang_item"
                onClick={() => {
                  setLang('ua');
                  setShowLangPopup(!showLangPopup);
                }}
                to={linkUA}
              >
                <div className="ico_ua"></div>
                <span>Українська</span>
              </NavLink>
              <NavLink
                className="lang_item"
                onClick={() => {
                  setLang('en');
                  setShowLangPopup(!showLangPopup);
                }}
                to={linkEN}
              >
                <div className="ico_en"></div>
                <span>English</span>
              </NavLink>
            </div>
          </div> } */}
        </div>
      </div>
    </nav>
  );
}
