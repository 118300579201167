import React, { useContext, useEffect } from 'react'
import Form from '../../components/Form/Form';
import DataContext from '../../context/DataContext';

export default function Missing({text, openLang}) {
    const { setLinkEN, setLinkUA, setLang } = useContext(DataContext);
    useEffect(() => {
      setLinkEN('/en/*');
      setLinkUA('/*');
      setLang(openLang);
    }, []);
  return (
    <main>
        <div className="bg bg-footer"></div>
        <header className="header header-about container">
        <div className="header-overlay gradient"></div>
        <h1>{text.title}</h1>
        <Form name='order_about'/>
      </header>
    </main>
  )
}
