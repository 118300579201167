import React, { useState } from 'react';

export default function FaqItem({ el, img }) {
  const [open, setOpen] = useState(false);
  return (
    <div className="faq_item">
      <div className={open ? 'faq_title active' : 'faq_title'} onClick={() => setOpen(!open)}>
        <p>{el.title}</p>
        <div className="faq_arrow"></div>
      </div>
      <div className={open ? 'faq_body active' : 'faq_body'}>
        <div className="faq_inner">
          <div className="faq_left">
            <img
              src={require('../../assets/img/faq/' + img + '.png')}
              alt="not found"
            />
          </div>
          <div className="faq_right">
            <p>{el.p}</p>
            <ul>
              {el.ul.map((li, i) => (
                <li key={i}>{li}</li>
              ))}
            </ul>
            {el.padd && <p>{el.p2}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}
