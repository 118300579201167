import Footer from "./components/Footer/Footer";
import { Route, Routes } from "react-router-dom";
import { locals } from "./locals";
import Nav from "./components/Nav/Nav";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import AboutEn from "./Pages/About/AboutEn";
import ScrollToTop from "./components/scrollToTop/scrollToTop";
import Rooms from "./Pages/Rooms/Rooms";
import RoomsEn from "./Pages/Rooms/RoomsEn";
import RoomsVip from "./Pages/RoomsVip/RoomsVip";
import RoomsVipEn from "./Pages/RoomsVip/RoomsVipEn";
import Capsule from "./Pages/Capsule/Capsule";
import CapsuleEn from "./Pages/Capsule/CapsuleEn";
import Contacts from "./Pages/Contacts/Contacts";
import ContactsEn from "./Pages/Contacts/ContactsEn";
import Coworking from "./Pages/Coworking/Coworking";
import CoworkingEn from "./Pages/Coworking/CoworkingEn";
import Missing from "./Pages/Missing/Missing";
import MissingEn from "./Pages/Missing/MissingEn";
import Franchise from "./Pages/Franchise/Franchise";
import Booking from "./Pages/Booking/Booking";
import BookingEn from "./Pages/Booking/BookingEn";
import HomeEn from "./Pages/Home/HomeEn";
import { HelmetProvider } from "react-helmet-async";
function App() {
  const { en, ua } = locals;
  return (
    <div className="App">
      <HelmetProvider>
        <div className="wrapper">
          <ScrollToTop />
          <Nav text={locals} />
          <Routes>
            <Route path="/">
              <Route index element={<Home openLang={'ua'} text={ua.homePage} />} />
              <Route path="about" element={<About openLang={'ua'} text={ua.aboutPage} />} />
              <Route path="roomsregular" element={<Rooms openLang={'ua'} text={ua.roomPage} />} />
              <Route path="rooms" element={<RoomsVip openLang={'ua'} text={ua.roomPageVip} />} />
              <Route path="coworking" element={<Coworking openLang={'ua'} text={ua.coworkingPage} />} />
              <Route path="capsules" element={<Capsule openLang={'ua'} text={ua.capsulePage} />} />
              <Route path="contacts" element={<Contacts openLang={'ua'} text={ua.contactPage} />} />
              <Route path="franchise" element={<Franchise openLang={'ua'} text={ua.franchisePage} />} />
              <Route path="booking" element={<Booking openLang={'ua'} />} />
              <Route path="*" element={<Missing openLang={'ua'} text={ua.missingPage} />} />
            </Route>
            <Route path="en">
              <Route index element={<HomeEn openLang={'en'} text={en.homePage} />} />
              <Route path="about" element={<AboutEn openLang={'en'} text={en.aboutPage} />} />
              <Route path="roomsregular" element={<RoomsEn openLang={'en'} text={en.roomPage} />} />
              <Route path="rooms" element={<RoomsVipEn openLang={'en'} text={en.roomPageVip} />} />
              <Route path="coworking" element={<CoworkingEn openLang={'en'} text={en.coworkingPage} />} />
              <Route path="capsules" element={<CapsuleEn openLang={'en'} text={en.capsulePage} />} />
              <Route path="contacts" element={<ContactsEn openLang={'en'} text={en.contactPage} />} />
              <Route path="franchise" element={<Franchise openLang={'en'} text={en.franchisePage} />} />
              <Route path="booking" element={<BookingEn openLang={'en'} />} />
              <Route path="*" element={<MissingEn openLang={'en'} text={en.missingPage} />} />
            </Route>
          </Routes>
          <Footer text={locals} />
        </div>
      </HelmetProvider>
    </div>
  );
}

export default App;
