import React from 'react'
import FaqItem from './FaqItem'
import './style.scss'

export default function Faq({arr}) {
  return (
    <div className="faq">
        {arr.map((el, index)=>(
            <FaqItem key={index} img={index+1} el={el}/>
        ))}
    </div>
  )
}
