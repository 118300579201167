import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import DataContext from '../../context/DataContext';
import './style.scss';

export default function Footer({ text }) {
  const { lang } = useContext(DataContext);
  const sublink = () => {
    if (lang === 'ua') {
      return '';
    } else {
      return lang;
    }
  };
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_left">
          <div className="footer_logo_block">
            <NavLink className="logo logo_footer" to={`${sublink()}/`}>
              {' '}
            </NavLink>
            <span>All rights reserved. Copyright {new Date().getFullYear()}</span>
          </div>

          <div className="footer_menu">
            <NavLink
              end
              className={({ isActive }) =>
                isActive ? 'navlink active' : 'navlink'
              }
              to={`${sublink()}/`}
            >
              {text[lang].menu.home}
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? 'navlink active' : 'navlink'
              }
              to={`${sublink()}/about`}
            >
              {text[lang].menu.about}
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? 'navlink active' : 'navlink'
              }
              to={`${sublink()}/rooms`}
            >
              {text[lang].menu.rooms}
            </NavLink>
            <NavLink className={({ isActive }) => (isActive ? "navlink active" : "navlink")}  to={`${sublink()}/capsules`}>{text[lang].menu.capsules}</NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? 'navlink active' : 'navlink'
              }
              to={`${sublink()}/coworking`}
            >
              {text[lang].menu.coworking}
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? 'navlink active' : 'navlink'
              }
              to={`${sublink()}/contacts`}
            >
              {text[lang].menu.contacts}
            </NavLink>
          </div>
        </div>
        <div className="footer_right">
          <p>{text[lang].footer.adress_title}</p>
          <h3><a href="https://goo.gl/maps/cRVoDY9X3w4XE93c7" rel="noreferrer" target='_blank'>{text[lang].footer.adress}</a></h3>
          <p>{text[lang].footer.phone_title}</p>
          <h3 className='h3_mb_2'><a href="tel:+380936020900">{text[lang].footer.phone}</a></h3>
          <h3><a href="tel:+380930075046">+38 093 007 50 46</a></h3>
          <p>{text[lang].footer.email_title}</p>
          <h3><a href={`mailto:${text[lang].footer.email}`}>{text[lang].footer.email}</a></h3>

        </div>
        <span onClick={()=>{window.scrollTo(0, 0);}} className='top'> </span>
      </div>
    </footer>
  );
}
