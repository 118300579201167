import React, { useContext, useEffect } from 'react';
import DataContext from '../../context/DataContext';
import './style.scss';

export default function Booking({ openLang }) {
  const { lang, setLinkEN, setLinkUA, setLang } = useContext(DataContext);
  useEffect(() => {
    setLinkEN(`/en/booking/${window.location.search}`);
    setLinkUA(`/booking/${window.location.search}`);
    setLang(openLang);
  }, []);

  // function bookingForm(w) {
  //   var q = [
  //     ['setContext', 'TL-INT-kievhostel_space.new', 'uk'],
  //     [
  //       'embed',
  //       'booking-form',
  //       {
  //         container: 'tl-booking-form',
  //       },
  //     ],
  //   ];
  //   var h = [
  //     'ua-ibe.tlintegration-eu.com',
  //     'ibe.tlintegration-eu.com',
  //     'ibe.tlintegrationfb-eu.com',
  //   ];
  //   var t = (w.travelline = w.travelline || {}),
  //     ti = (t.integration = t.integration || {});
  //   ti.__cq = ti.__cq ? ti.__cq.concat(q) : q;
  //   if (!ti.__loader) {
  //     ti.__loader = true;
  //     var d = w.document,
  //       c =
  //         d.getElementsByTagName('head')[0] ||
  //         d.getElementsByTagName('body')[0];
  //     function e(s, f) {
  //       return function () {
  //         // w.TL || (c.removeChild(s), f());
  //       };
  //     }
  //     (function l(h) {
  //       if (0 === h.length) return;
  //       var s = d.createElement('script');
  //       s.type = 'text/javascript';
  //       s.async = !0;
  //       s.src = 'https://' + h[0] + '/integration/loader.js';
  //       s.onerror = s.onload = e(s, function () {
  //         l(h.slice(1, h.length));
  //       });
  //       c.appendChild(s);
  //     })(h);
  //   }
  // }
  // function bookingFormEn(w) {
  //   var q = [
  //     ['setContext', 'TL-INT-kievhostel_space.new', 'en'],
  //     [
  //       'embed',
  //       'booking-form',
  //       {
  //         container: 'tl-booking-form-en',
  //       },
  //     ],
  //   ];
  //   var h = [
  //     'ua-ibe.tlintegration-eu.com',
  //     'ibe.tlintegration-eu.com',
  //     'ibe.tlintegrationfb-eu.com',
  //   ];
  //   var t = (w.travelline = w.travelline || {}),
  //     ti = (t.integration = t.integration || {});
  //   ti.__cq = ti.__cq ? ti.__cq.concat(q) : q;
  //   if (!ti.__loader) {
  //     ti.__loader = true;
  //     var d = w.document,
  //       c =
  //         d.getElementsByTagName('head')[0] ||
  //         d.getElementsByTagName('body')[0];
  //     function e(s, f) {
  //       return function () {
  //         //   w.TL || (c.removeChild(s), f());
  //       };
  //     }
  //     (function l(h) {
  //       if (0 === h.length) return;
  //       var s = d.createElement('script');
  //       s.type = 'text/javascript';
  //       s.async = !0;
  //       s.src = 'https://' + h[0] + '/integration/loader.js';
  //       s.onerror = s.onload = e(s, function () {
  //         l(h.slice(1, h.length));
  //       });
  //       c.appendChild(s);
  //     })(h);
  //   }
  // }
  useEffect(() => {
    // bookingForm(window);
    // bookingFormEn(window);
  }, []);

  return (
    <main className="booking_wrapper">
      <div className="bg bg-footer"></div>
      
      <div id="tl-booking-form" className="tl-container"></div>
    </main>
  );
}
