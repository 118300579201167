import React, { useState } from 'react';
import './style.scss'
export default function Tabs({ text, imgClass }) {
  const [desc, setDesc] = useState(0);
  return (
    <div className="description_wrapper">
      <div className="description_left">
        <div className={`${imgClass+(desc+1)} description_photo`}>

        </div>
      </div>
      <div className="description_right">
        <h3 className={imgClass+'_title'}>{text.description.name}</h3>
        <div className="place_description_list">
          {text.description.stuff.map((el, index) => (
            <div key={index} onClick={() => setDesc(index)} className={desc===index?"desc_item active":"desc_item"}>
              <p>{el}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
