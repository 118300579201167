// import { useContext } from 'react';
// import DataContext from '../../context/DataContext';
import { useContext, useEffect, useState } from 'react';
import './style.scss';
import Slider from '../../components/Slider/Slider';
import { NavLink } from 'react-router-dom';
import DataContext from '../../context/DataContext';
import Gallery from '../../components/Gallery/Gallery';
import Form from '../../components/Form/Form';
import FormDuplicate from '../../components/Form/FormDuplicate';

export default function Home({ text, openLang }) {
  const [tab, setTab] = useState(0);
  const { lang, setLang, setLinkEN, setLinkUA } = useContext(DataContext);

  useEffect(() => {
    setLang(openLang);
    setLinkEN('/en/');
    setLinkUA('/');
  });
  const sublink = () => {
    if (lang === 'ua') {
      return '';
    } else {
      return lang;
    }
  };
  return (
    <main>
      <div className="bg bg-1"></div>
      <div className="bg bg-2"></div>
      <div className="bg bg-3"></div>

      <header className="header header-home container">
        <div className="header-overlay gradient"></div>

        <h1>
          <b>Kyiv Hostel</b>
          <br />
          {text.head.title}
        </h1>
        <p>{text.head.desc}</p>
        <a
          href="https://www.booking.com"
          target="_blank"
          rel="noreferrer"
          className="booking"
        >
          {' '}
        </a>
        <a
          href="https://goo.gl/maps/cRVoDY9X3w4XE93c7"
          target="_blank"
          rel="noreferrer"
          className="head_map"
        >
          <span>{text.head.map_title}</span>
          <span>{text.head.map_desc}</span>
        </a>
        <div className="head_benefits">
          {text.head.benefits.map((el, index) => (
            <div
              key={index}
              className={`head_benefits_item head_benefits_${index + 1}`}
            >
              <span className="title">{el}</span>
            </div>
          ))}
        </div>
        <Form />
      </header>

      <div className="home_about container">
        <div className="home_about_left">
          <h2>{text.about.title}</h2>
          <p>{text.about.desc}</p>
          <div className="home_about_benefits">
            <div className="home_about_benefits_item">
              <p>{text.about.metro_title}</p>
              <h3>{text.about.metro_text}</h3>
            </div>
            <div className="home_about_benefits_item">
              <p>{text.about.park_title}</p>
              <h3>{text.about.park_text}</h3>
            </div>
          </div>
          <p>{text.about.text}</p>
          <NavLink to={`${sublink()}/about`} className="btn">
            {' '}
            {text.about.read_more}
          </NavLink>
        </div>
        <div className="home_about_right">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d5079.483395346681!2d30.40529938372262!3d50.46453450415981!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e2!4m5!1s0x40d4cc5c80ab3c85%3A0xbf8fd649a2d0cd6b!2z0J3QuNCy0LrQuA!3m2!1d50.4586495!2d30.404373!4m5!1s0x40d4ce6cd9f2c275%3A0x260e943541ea50a8!2z0KXQvtGB0YLQtdC7IEtZSVYgSE9TVEVM!3m2!1d50.4721707!2d30.4035165!5e0!3m2!1sru!2sua!4v1665491413016!5m2!1sru!2sua"
            width="100%"
            height="100%"
            style={{ border: 'none' }}
            loading="lazy"
            title="map_home"
          ></iframe>
        </div>
      </div>

      <div className="numbers container">
        <h2>{text.numbers_title}</h2>
        <p>{text.numbers_desc}</p>
        <div className="numbers_list">
          {text.numbers.map((el, index) => (
            <div className="numbers_item" key={index}>
              <div className={`number_item_img number_item_img_${index}`}></div>
              <div className="numbers_item_content">
                <h2>{el.title}</h2>
                <p>{el.desc}</p>
                <h3>{el.price} ₴</h3>
                <NavLink
                  className="btn btn-numbers"
                  to={`${sublink()}/${el.link}`}
                >
                  {text.numbers_more}
                </NavLink>
              </div>
            </div>
          ))}
        </div>
        <div className="numbers_additional">
          <h2>{text.numbers_additional.title}</h2>
          <div className="numbers_additional_list">
            {text.numbers_additional.services.map((el, index) => (
              <div className="numbers_additional_item" key={index}>
                <p>{el}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="home_add container">
        <div className="home_add_header">
          <h2>{text.services.title}</h2>
          <p>{text.services.subtitle}</p>
        </div>
        <div className="services_head">
          {text.services.tabs.map((el, index) => (
            <div
              key={index}
              onClick={() => setTab(index)}
              className={tab === index ? 'head_tab active' : 'head_tab'}
            >
              <h3>{el.title}</h3>
            </div>
          ))}
        </div>

        <div className="services_body">
          {text.services.tabs.map((el, index) => (
            <div key={index} className={tab === index ? 'active' : null}>
              <div className="body_left">
                <Slider photoClass={el.imgClass} />
              </div>
              <div className="body_right">
                <h2>{el.title}</h2>
                <p>{el.description}</p>
                <div className="body_info">
                  <p>{el.info}</p>
                  <ul>
                    {el.infoDetails.map((el, index) => (
                      <li key={index}>
                        <b>{el}</b>
                      </li>
                    ))}
                  </ul>
                </div>
                {el.link && (
                  <NavLink className="btn" to={`${sublink()}/${el.link}`}>
                    {el.read}
                  </NavLink>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="home_benefits container">
          <h2>{text.home_benefits.title}</h2>
          <div className="home_benefits_list">
            {text.home_benefits.list.map((el, index) => (
              <div key={index} className="home_benefits_item">
                <h3>{el.title}</h3>
                <p>{el.desc}</p>
              </div>
            ))}
          </div>
        </div>
        <FormDuplicate name="add" />
      </div>
      <div className="partners container">
        <h2>{text.partners}</h2>
        <div className="partners_list">
          <div className="partner_item"></div>
          <div className="partner_item"></div>
          <div className="partner_item"></div>
          <div className="partner_item"></div>
          <div className="partner_item"></div>
          <div className="partner_item"></div>
        </div>
      </div>
      <div className="gallery_wrapper container">
        <h2>{text.gallery_home.title}</h2>
        <p>{text.gallery_home.desc}</p>
        <Gallery name="gallery_home" />
      </div>
    </main>
  );
}
