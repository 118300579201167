import { useContext, useEffect } from 'react';
import Form from '../../components/Form/Form';
import DataContext from '../../context/DataContext';
import './style.scss';

export default function Contacts({ openLang, text }) {
  const { setLinkEN, setLinkUA, setLang } = useContext(DataContext);

  useEffect(() => {
    setLinkEN('/en/contacts');
    setLinkUA('/contacts');
    setLang(openLang);
  }, []);
  return (
    <main>
      <div className="bg bg-6"></div>
      <header className="header header-contacts container">
        <div className="header-overlay gradient"></div>
        <h1>{text.head.title}</h1>
        <Form name="order_about" />
      </header>
      <div className="contacts container">
        <div className="contacts_left">
          <h2>{text.messengers}</h2>
          <div className="contacts_messengers">
            <a
              className="tg_white"
              target="_blank"
              rel="noreferrer"
              href="tg://resolve?domain=KyivHostel"
            >
              {' '}
            </a>
            <a className="fb" href="viber://add?number=380930075046">
              {' '}
            </a>
            <a className='email' href="mailto:hostelkyiv24@gmail.com"> </a>
          </div>
          <div className="contacts_phone">
            <h2>Номери телефонів</h2>
            <h3>
              <a href="tel:+380936020900">+38 093 602 09 00</a>
            </h3>
            <h3>
              <a href="tel:+380930075046">+38 093 007 50 46</a>
            </h3>
          </div>
          <h2>{text.transport}</h2>
          <div className="contacts_trasport">
            <div className="contacts_trasport_item">
              <p>{text.bus}</p>
              <h3>119, 22, 78</h3>
            </div>
            <div className="contacts_trasport_item">
              <p>{text.metro}</p>
              <h3>{text.station}</h3>
            </div>
            <div className="contacts_trasport_item">
              <p>{text.trolleybus}</p>
              <h3>19Д, 30</h3>
            </div>
            <div className="contacts_trasport_item">
              <p>{text.route}</p>
              <h3>227, 463, 496</h3>
            </div>
          </div>
        </div>
        <div className="contacts_right">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12798.030212623496!2d30.39689384302757!3d50.46994970709602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4ce6cd9f2c275%3A0x260e943541ea50a8!2z0KXQvtGB0YLQtdC7IEtJRVYgSE9TVEVMINC60LDQv9GB0YPQu9GM0L3Ri9C5INC-0YLQtdC70Ywg0LIg0JrQuNC10LLQtQ!5e0!3m2!1sru!2sua!4v1665730320913!5m2!1sru!2sua"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen={true}
            loading="lazy"
            title="map_contacts"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </main>
  );
}
