export const locals = {
    ua: {
        menu: {
            home: 'Головна',
            about: 'Про нас',
            rooms: 'Номери',
            capsules: 'Капсули',
            coworking: 'Коворкінг',
            contacts: 'Контакти',
            phone: 'Контактні номери телефону'
        },
        footer: {
            adress_title: 'Наша адреса',
            adress: 'вул. Салютна 5Б',
            phone_title: 'Контактні номери телефону',
            phone: '+38 093 602 09 00',
            email_title: 'Електронна пошта',
            email: 'hostelkyiv24@gmail.com'
        },
        homePage: {
            head: {
                title: 'Капсульний готель в Києві',
                titleAccent: 'Kyiv Hostel',
                desc: 'Капсульний готель нового покоління, що повністю зруйнує ваші стереотипи про хостели!',
                benefits: ['Заселення 24/7', 'Зона Харчування', 'Коворкінг', 'Пральня і сушилка'],
                map_title: 'Маршрут',
                map_desc: 'Ми на мапі'
            },
            about: {
                title: 'Про нас',
                desc: 'KYIV HOSTEL –  це капсульний готель, досить нове явище у готельній сфері, яке поки що не має аналогів по Україні, але вже оцінене і користується великим попитом за кордоном. Головною особливістю KYIV HOSTEL є органічне поєднання приємної дружньої атмосфери хостелу із  сервісом готельного рівня.',
                metro_title: 'Метро Нивки',
                metro_text: '15 хв пішки',
                park_title: 'Міський парк',
                park_text: '5 хв пішки',
                text: 'Наш готель розташований по вул. Салютній, 5Б, навпроти ЖК “Файна Таун” -  у жвавому районі зі зручною транспортною розв’язкою. Завдяки вигідній локації, KYIV HOSTEL знаходиться на відстані не більше 7-ми станцій метро від усіх визначних пам’яток Києва!   А зовсім поруч є продуктові крамниці на будь-який смак: “АТБ”, “Сільпо”, “Мегамаркет”,  “Фора”.',
                read_more: 'Дізнатись більше'
            },
            numbers: [
                { title: 'Капсула', desc: 'у 4 - місному загальному номері', price: 'від 410', link:'capsules' },
                { title: 'Капсула', desc: 'у 8 - місному загальному номері', price: 'від 320', link:'capsules' },
                { title: 'Капсула', desc: 'у 8 - місному жіночому номері', price: 'від 320', link:'capsules' },
                { title: 'Капсула', desc: 'у 12 - місному загальному номері', price: 'від 320', link:'capsules' },
                { title: 'Окремі апартаменти', desc: '2 - місні, із вбиральнею', price: 'від 800', link:'rooms' },
                { title: 'Окремі апартаменти', desc: '2 - місні, без вбиральні', price: 'від 700', link:'roomsregular' },
            ],
            numbers_title: 'Типи номерів',
            numbers_desc: 'До послуг гостей номерний фонд у складі 13-ти номерів: 9 загальних кімнат із капсульним наповненням, а також 4 номери сімейного типу із двоспальними ліжками. Максимальне завантаження KYIV HOSTEL розраховане на 92 особи.',
            numbers_more: 'Переглянути',
            numbers_additional: {
                title: 'Додаткові сервіси',
                services: ['Регулярне прибирання', 'Безкоштовні Wi-Fi, паркінг, курилка', 'Англомовний персонал', 'Сховище особистих речей']
            },
            services: {
                title: 'Додаткові послуги',
                subtitle: 'KYIV HOSTEL має усе необхідне для комфортного життя, цікавих зустрічей і спілкування, ефективної роботи та відпочинку, і навіть спортивних тренувань.',
                tabs: [
                    // {
                    //     title: 'Коворкінг',
                    //     description: 'Світле, тихе і затишне приміщення для роботи. У розпорядженні кожного гостя - робоче місце зі столом-партою, кріслом і персональною розеткою.  До ваших послуг також проектор, фліпчарт і бібліотека.',
                    //     info: 'Ємність простору:',
                    //     infoDetails: ['18 робочих місць'],
                    //     link: 'coworking',
                    //     imgClass: 'coworking_tab',
                    //     read: 'Дізнатись більше'
                    // },
                    {
                        title: 'Зала конференцій',
                        description: 'За потреби, наш затишний коворкінг легко трансформується в універсальну конференц-залу. Тут можна провести ефективні корпоративні збори, презентації, ділові переговори, тренінги, навчання тощо. Для цього просто замовте оренду конференц-зали у потрібний вам день і час.',
                        info: 'Додатково у вашому розпорядженні:',
                        infoDetails: ['Проектор', 'Фліпчарт', 'Принтер'],
                        link: 'coworking',
                        imgClass: 'conference_tab',
                        read: 'Дізнатись більше'
                    },
                    {
                        title: 'Фітнес-зала',
                        description: 'Наш простір враховує практично усе, необхідне вам для повсякденного життя. Так, поверхом нижче вас завжди радий вітати стильний і великий спортивний комплекс KD GYM.',
                        info: 'Доступні послуги:',
                        infoDetails: ['Нові сучасні тренажери', 'Кардіозона','Тренування із тренером','Групові заняття (степ-аеробіка, стретчинг, функціональний тренінг)','Солярій'],
                        imgClass: 'fitness_tab',
                    },
                    {
                        title: 'Обідня зона',
                        description: 'У  нас вам не потрібно витрачати власний час, готуючи їжу у дискомфорті, притаманному загальним кухням звичайних хостелів. Усі ці турботи ми взяли на себе –  вам залишається насолоджуватися чудовими стравами і напоями, спокійно сидячи у їдальні-кафе KYIV HOSTEL.',
                        info: 'Для вас:',
                        infoDetails: ['Смачна кухня за доступними цінами','Сувора чистота і санітарія','Затишне кафе'],
                        imgClass: 'dinner_tab',
                    },
                    {
                        title: 'Пральня',
                        description: 'KYIV HOSTEL береже дорогоцінний час своїх гостей, забезпечуючи повний комплекс послуг професійної пральні. Просто віддайте брудний одяг на рецепцію і заповніть бланк щодо параметрів прання. У визначений час ви отримаєте його чистим і сухим. ',
                        info: 'Що ви отримуєте:',
                        infoDetails: ['Економія особистого часу','Готовий одяг за лічені години','Якісне прання за мінімальною ціною'],
                        imgClass: 'washing_tab',
                    },
                    {
                        title: 'Лаунж-зона',
                        description: 'Для вашого комфорту поруч із кафе ми організували суміжну зону відпочинку. Це мультифункціональна частина приміщення, де можна зустрітися й поспілкуватися із друзями, пограти у настільні ігри, почекати на когось, подивитися TV або просто спокійно випити каву.',
                        info: 'Для вашого відпочинку:',
                        infoDetails: ['Зручні дивани','Великий телевізор','Стильний інтер’єр', 'Приємна атмосфера'],
                        imgClass: 'relax_tab',
                    }
                ]
            },
            home_benefits: {
                title: 'Наші переваги',
                list: [{ title: 'Простір для життя', desc: 'Розумне зонування для вашого щоденного комфорту' }, { title: 'Харчування “під ключ”', desc: 'Смачнючі сніданки, обіди, вечері;  чиста їдальня, затишне кафе' }, { title: 'Приємні ціни', desc: 'Співвідношення цін і рівня якості, яке  вам точно сподобається' }, { title: 'Безпека і турбота', desc: 'Сучасні вентиляційна і пожежна системи; власне бомбосховище' }, { title: 'Ергономічні спальні місця', desc: 'Великі ліжка, зручні матраци, просторі шухляди для речей' }, { title: 'Пральний сервіс', desc: 'Професійна пральня й сушарка швидко зроблять усе за вас' },]
            },
            partners: 'Наші партнери',
            gallery_home: { title: 'Галерея', desc: 'Запрошуємо на фото-екскурсію до KYIV HOSTEL:' },
            coworking: {
                title: 'Коворкінг',
                faq: {
                    title: 'Ми пропонуємо набагато більше, ніж вирішення цих проблем ',
                    list: [{
                        title: 'Комфортни спальні місця',
                        img: '1',
                        p: 'Гость хостела платит, прежде всего, за спальное место. Мы считаем, что оно должно быть действительно качественным и комфортным. KYIV HOSTEL обеспечивает клиента инновационной, надежной кроватью собственной проектировки глубиной 110 см. Удобный ортопедический матрац без пружин (на пене) Заботы по подготовке и смене постели лежат на горничной.',
                        ul: ['Подушка с наполнением полиэфирными шариками', 'Водонепроницаемый наматрацник',
                            'Удобный ортопедический матрац без пружин', 'Сатиновое одеяло']
                    },
                    {
                        title: 'Мы считаем, что оно должно быть действительно качественным',
                        img: '2',
                        p: 'Гость хостела платит, прежде всего, за спальное место. Мы считаем, что оно должно быть действительно качественным и комфортным. KYIV HOSTEL обеспечивает клиента инновационной, надежной кроватью собственной проектировки глубиной 110 см. Удобный ортопедический матрац без пружин (на пене) Заботы по подготовке и смене постели лежат на горничной.',
                        ul: ['Подушка с наполнением полиэфирными шариками', 'Водонепроницаемый наматрацник',
                            'Удобный ортопедический матрац без пружин', 'Сатиновое одеяло']
                    },
                    {
                        title: 'KYIV HOSTEL обеспечивает клиента инновационной',
                        img: '3',
                        p: 'Гость хостела платит, прежде всего, за спальное место. Мы считаем, что оно должно быть действительно качественным и комфортным. KYIV HOSTEL обеспечивает клиента инновационной, надежной кроватью собственной проектировки глубиной 110 см. Удобный ортопедический матрац без пружин (на пене) Заботы по подготовке и смене постели лежат на горничной.',
                        ul: ['Подушка с наполнением полиэфирными шариками', 'Водонепроницаемый наматрацник',
                            'Удобный ортопедический матрац без пружин', 'Сатиновое одеяло']
                    }
                    ]
                }
            }
        },
        aboutPage: {
            head: {
                title: 'Про нас'
            },
            concept: {
                title: 'Концепція',
                inner_title: 'KYIV Hostel - це',
                desc: 'інноваційний хостел, що ламає стереотипи, які склалися роками про рівень якості, комфорту та сервісу в українських містах тимчасового проживання такого формату. Ідея розробки даного проекту виникла як реакція на поточний стан більшості хостелів Києва і, тим більше, інших міст нашої країни. Ми створили хостел нового покоління, за яким – майбутнє!',
                benefits: ['Комплексний сервіс', 'Якість та доступність', 'Комфорт та спрямованність на гостя']
            },
            pain: {
                title: 'Болі гостей звичайних хостелів',
                list: [{ title: 'Недостатня зонованість', desc: 'Відсутність місця для паління та окремих приміщень для роботи та ділових зустрічей; тісна лаунж-зона.' }, { title: 'Незадовільна якість сну', desc: 'Незручні та надто маленькі ліжка з дешевими матрацами та подушками, неякісна постільна білизна.' }, { title: 'Неможливість прання речей', desc: 'Дискомфортний процес самостійного прання (черга, очікування). Швидкий вихід з ладу прального обладнання.' }, { title: 'Відсутність зони харчування', desc: 'Самостійне приготування їжі, тісна кухня, загальний посуд, антисанітарія, запах у холодильнику.' }]
            },
            faq: {
                title: 'Вміючи зазделегідь передбачити ваші потреби, ми пропонуємо набагато більше, ніж вирішення зазначених проблем:',
                list: [{
                    title: 'Зручна локація',
                    p: 'KYIV HOSTEL знаходиться по вул. Салютна, 5Б, навпроти ЖК “Файна Таун” -  у жвавій частині Києва із добре розвинутою інфраструктурою, всього у 12 хвилинах від станції метро Нивки.',
                    ul: ['Від усіх визначних пам’яток Києва нас віддаляє не більше 7-ми станцій метро', '5 хвилин пішки від чудового міського парку', 'Поруч “АТБ”, “Сільпо”, “Мегамаркет”,  “Фора”.']
                },
                {
                    title: 'Різнобічний сервіс',
                    p: 'Створюючи простір для якісного повсякденного життя, ми потурбувалися практично про усі ваші потреби. Наша мета -  це комплексний комфорт і задоволення гостей від їхнього перебування у KYIV HOSTEL. ',
                    ul: ['Зручні номери і спальні місця, продумані до найменших дрібниць', 'Повна організація харчування', 'Пральні послуги «під ключ»', 'Окремі зони для роботи і відпочинку', 'Дружня атмосфера у поєднанні з точним сервісом']
                },
                {
                    title: 'Комфортні спальні місця',
                    p: 'Гість хостелу сплачує, передусім, за спальне місце. Ми вважаємо, що воно має бути дійсно якісним і комфортним. Клієнти KYIV HOSTEL забезпечені надійним інноваційним  ліжком нашої власної розробки:',
                    ul: ['Просторе спальне місце глибиною 110 см', 'Подушка із наповненням поліефірними кульками', 'Зручний ортопедичний матрац на піні (пружини повністю відсутні!)', 'Водонепроникний наматрацник', 'Сатинова ковдра '],
                    padd: 'На кожного гостя чекає дбайливо застелене ліжко. Клопіт із заміною білизни також покладено на покоївку'
                },
                {
                    title: 'Оптимальна організація харчування',
                    p: 'Для вас у KYIV HOSTEL працює професійна домашня кухня. А чиста й охайна їдальня радо зустріне і нагодує вас протягом усього дня. ',
                    ul: ['Смачні страви і напої за помірними цінами', 'Різноманітне меню на ваш вибір', 'Ідеальна чистота']
                },
                {
                    title: 'Безпека і турбота про здоров’я',
                    p: 'Наші пріоритети – це ваш комфорт і добре самопочуття. Тому весь спектр послуг KYIV HOSTEL передусім враховує безпеку і зручність для людського організму.',
                    ul: ['Жорстке дотримання санітарних вимог', 'Інноваційні ергономічні ліжка', 'Шумоізоляція, що забезпечує якісний сон', 'Сучасні вентиляційна і пожежна системи', 'Безпечне бомбосховище']
                },
                {
                    title: 'Унікальний пральний сервіс',
                    p: 'Крім усього іншого, ми організували професійну пральню, завдяки якій гості KYIV HOSTEL не витрачають дорогоцінний час на прання. ',
                    ul: ['Швидке прання й сушіння одягу «під ключ»', 'Нове якісне обладнання', 'Безперебійна робота пральні, завдяки електрогенераторам']
                },
                {
                    title: 'Коворкінг-простір, конференц-зала',
                    p: 'На території нашого хостелу ви можете скористатися коворкінгом, який за необхідності й попереднім замовленням легко трансформується у вмістку конференц-залу до 18-ти осіб. ',
                    ul: ['Тихе, світле приміщення, наповнене робочою атмосферою', 'Індивідуальне робоче місце із власним столом, стільцем, розеткою', 'Безперебійне світло, швидкісний інтернет та Інтернет Starlink для непердбачуваних ситуацій', 'Фліпчарт, проектор, принтер']
                },
                {
                    title: 'Фітнес-клуб',
                    p: 'Поверхом нижче знаходиться спортивний клуб KD GYM. Це сучасний заклад із новим якісним устаткуванням, приємним стильним дизайном, хорошим освітленням, комфортними роздягальнями й великими вікнами, завдяки яким Ви не відчуєте духоту підвальних приміщень. ',
                    ul: ['Широкий асортимент (тренажери, кардіозона, індивідуальні тренування, групові заняття зі степ-аеробіки і стретчингу)','Кваліфіковані тренери','Солярій','Дружня й невимушена атмосфера']
                },
                ]
            }
        },
        capsulePage: {
            head: {
                name: 'Капсула',
                // title: 'В 8-Ми Местном Номере',
                desc: 'Загальні номери оснащені комфортабельними капсулами зі зручними спальними місцями та індивідуальними локерами для зберігання речей',
                benefits: ['Заселення 24/7', 'Зона Харчування', 'Коворкінг'],
                priceFrom: 'Ціна за добу від:',
                currency: 'грн',
                price: '320',
            },
            description: {
                title: 'Опис капсули',
                name: 'Спальне місце',
                stuff: ['Рушники', 'Персональне освітлення', 'Занавіска', 'Сторедж для речей', 'Місце для зарядки'],
                more_title: 'Детальніше про номер',
                more_text: ['Простора та ергономічна одномісна капсула з натуральної сировини, обладнана комодом для багажу, світильником і розеткою. Комод відкривається  індивідуальним ключем - 100% безпека і охорона ваших речей.','Широке спальне місце (110 см) оснащене комфортним ортопедичним матрацом на піні, водонепроникним наматрацником, сатиновою ковдрою, а також подушкою з поліефірних кульок.','Кожна капсула має індивідуальну занавіску , завдяки якій ви маєте у розпорядженні власний комфортний простір.'],
                benefits: [{ title: 'Підготовлене місце', desc: 'Застелене ліжко з білизною' }, { title: 'Комфорт і якісний сон', desc: 'Матраци без пружин, шумоізоляція' }]
            },
            gallery: { title: 'Як виглядає капсула', desc: 'Капсули повністю спроектовані і реалізовані командою KYIV HOSTEL.' },
        },
        roomPage: {
            head: {
                name: 'Номер',
                title: 'Без санвузла',
                desc: 'Окремий комфортабельний номер на двох зі зручними ліжками та індивідуальними місцями для зберігання речей.',
                benefits: ['Заселення 24/7', 'Зона Харчування', 'Коворкінг'],
                priceFrom: 'Ціна за добу від:',
                currency: 'грн',
                price: '700',
            },
            description: {
                title: 'Опис номера',
                name: 'Спальне місце',
                stuff: ['Рушники', 'Персональне освітлення', 'Занавіска', 'Сторедж для речей', 'Місце для зарядки'],
                more_title: 'Детальніше про номер',
                more_text: ['Просторий і затишний двомісний номер із туалетом і душем на поверсі.','Номер обладнаний двома односпальними ліжками, або одним двоспальним ліжком, комодом для зберігання речей, великим дзеркалом.','Широке спальне місце оснащене комфортним ортопедичним матрацом на піні, водонепроникним наматрацником, сатиновою ковдрою, а також подушкою з поліефірних кульок.'],
                benefits: [{ title: 'Підготовлене місце', desc: 'Застелене ліжко з білизною' }, { title: 'Комфорт і якісний сон', desc: 'Матраци без пружин, шумоізоляція' }],
                variant: {title: 'Номер з санвузлом', link: 'rooms'}
            },
            gallery: { title: 'Галерея', desc: 'Ви можете знайти більше фотографій на нашій сторінці в Instagram' },
        },
        roomPageVip: {
            head: {
                name: 'Номер',
                title: 'З санвузлом',
                desc: 'Окремий комфортабельний номер на двох зі зручними ліжками, власною вбиральнею та індивідуальними місцями для зберігання речей.',
                benefits: ['Заселення 24/7', 'Зона Харчування', 'Коворкінг'],
                priceFrom: 'Ціна за добу від:',
                currency: 'грн',
                price: '800',
            },
            description: {
                title: 'Опис Номера',
                name: 'Спальне місце',
                stuff: ['Рушники', 'Персональне освітлення', 'Занавіска', 'Сторедж для речей', 'Місце для зарядки'],
                more_title: 'Детальніше про номер',
                more_text: ['Просторий і затишний двомісний номер із персональними туалетом і душем всередині.','Номер обладнаний двома односпальними ліжками, або одним двоспальним ліжком, комодом для зберігання речей, великим дзеркалом.','Широке спальне місце оснащене комфортним ортопедичним матрацом на піні, водонепроникним наматрацником, сатиновою ковдрою, а також подушкою з поліефірних кульок.'],
                benefits: [{ title: 'Підготовлене місце', desc: 'Застелене ліжко з білизною' }, { title: 'Комфорт і якісний сон', desc: 'Матраци без пружин, шумоізоляція' }],
                variant: {title: 'Номер без санвузла', link: 'roomsregular'}
            },
            gallery: { title: 'Галерея', desc: 'Ви можете знайти більше фотографій на нашій сторінці в Instagram' },
        },
        contactPage: {
            head: {
                title: 'Контакти'
            },
            messengers: 'Наші месенджери:',
            transport: 'Як дістатися до готелю:',
            bus: 'Автобус',
            metro: 'Метро',
            station: 'ст. Нивки',
            trolleybus: 'Тролейбус',
            route: 'Маршрутка'
        },

        coworkingPage: {
            head: {
                title: 'Коворкінг',
                desc: 'У KIEV HOSTEL є чудовий коворкінг-простір на 18 робочих місць. Для мешканців готелю знижка!',
                benefits: ['Заселення 24/7', 'Зона Харчування', 'Коворкінг'],
                priceFrom: 'Ціна за робочий день:',
                currency: 'грн',
                price: '150',
            },
            description: {
                title: 'Коворкінг',
                name: 'Робоче місце',
                stuff: ['Персональні стіл і крісло', 'Стабільна електроенергія', 'Цілодобовий Wi-Fi Starlink', 'Принтер, фліпчарт', 'Бібліотека'],
                more_title: 'Детальніше про коворкінг',
                more_text: ['Світле і затишне приміщення, де можна повністю зосередитись на роботі. До ваших послуг  робоче місце з окремим столом і зручним кріслом, а також персональною розеткою.','За потреби ви можете скористатися невеличким фліпчартом, безкоштовною електронною бібліотекою, а також роздрукувати документи на загальному принтері.','Вартість оренди робочих місць для мешканців нашого хостелу - символічна!'],
                benefits: [{ title: 'Світло для роботи', desc: 'Безперебійне електропостачання' }, { title: 'Повне зосередження', desc: 'Тиха робоча атмосфера' }],
            },
            gallery: { title: 'Галерея', desc: 'Ви можете знайти більше фотографій на нашій сторінці в Instagram' },
        },
        missingPage: {
            title: 'Сторінка не знайдена'
        },
        franchisePage: {
            head: {
                title: 'Франшиза'
            },
            text: 'По питанням співробітництва звертайтесь за контактами:'
        }
    },
    en: {
        menu: {
            home: 'Home',
            about: 'About us',
            rooms: 'Rooms',
            capsules: 'Capsules',
            coworking: 'Coworking',
            contacts: 'Contacts',
            phone: 'Contact phone number'
        },
        footer: {
            adress_title: 'Our address',
            adress: 'st. Salyutna 5B',
            phone_title: 'Contact phone number',
            phone: '+38 093 602 09 00',
            email_title: 'Email',
            email: 'hostelkyiv24@gmail.com'
        },
        homePage: {
            head: {
                title: 'Capsule hotel in Kyiv',
                titleAccent: 'KYIV Hostel',
                desc: 'Kyiv Hostel is a modern capsule hotel in Kyiv. Our hotel is located in the Nivki microdistrict',
                benefits: ['Check-in 24/7', 'Food Zone', 'Coworking', 'Washer and Dryer'],
                map_title: 'Route',
                map_desc: 'We on the map'
            },
            about: {
                title: 'About us',
                desc: 'Kyiv Hostel is a modern capsule hotel in Kyiv. Our hostel is located in the "Nyvky" microdistrict.',
                metro_title: 'Nyvky Metro',
                metro_text: '15 min walk',
                park_title: 'City Park',
                park_text: '5 min walk',
                text: 'Thanks to the service of our inexpensive hotel, you can use the "Bike Now" bicycle rental, which will allow you to get to any district of Kyiv and leave it in one of three hundred possible parking lots.',
                read_more: 'Learn more'
            },
            numbers: [
                { title: 'Capsule', desc: 'In 8 bed room', price: 'from 220', link:'capsules' },
                { title: 'Capsule', desc: 'In 8 bed women room', price: 'from 250', link:'capsules' },
                { title: 'Capsule', desc: 'In 12 bed room', price: 'from 220', link:'capsules' },
                { title: 'Capsule', desc: 'In 8 bed room', price: 'from 300', link:'capsules' },
                { title: 'Separate room', desc: 'without a toilet', price: 'from 500', link:'rooms' },
                { title: 'Separate room', desc: 'with a toilet', price: 'from 700', link:'rooms' },
            ],
            numbers_title: 'Numbers',
            numbers_desc: 'Kyiv Hostel is a modern capsule hotel in Kyiv. Our hostel is located in the "Nyvky" microdistrict.',
            numbers_more: 'Look over',
            numbers_additional: {
                title: 'Additional services',
                services: ['Regular cleaning', 'Free Wi-Fi', 'Dining area', 'Storage for personal items']
            },
            services: {
                title: 'Capsule hotel in Kyiv',
                subtitle: 'Kyiv Hostel is a modern capsule hotel in Kyiv. Our hostel is located in the Nivki microdistrict',
                tabs: [
                    {
                        title: 'Coworking',
                        description: 'Kyiv Hostel is a modern capsule hotel in Kyiv. Our hostel is located.',
                        info: 'Space capacity',
                        infoDetails: ['24 workplaces'],
                        link: 'coworking',
                        imgClass: 'coworking_tab',
                        read: 'Read more'
                    },
                    {
                        title: 'Conference hall',
                        description: 'Kyiv Hostel is a modern capsule hotel in Kyiv. Our hostel is located in the Nivki microdistrict',
                        info: 'Number of seats',
                        infoDetails: ['20 tables', '40 chairs'],
                        link: 'conference',
                        imgClass: 'conference_tab',
                        read: 'Read more'
                    },
                    {
                        title: 'Dining area',
                        description: 'Kyiv Hostel is a modern capsule hotel in Kyiv. Our hostel is located in the Nivki microdistrict',
                        info: 'Number of seats',
                        infoDetails: ['18 tables'],
                        link: 'dinner',
                        imgClass: 'dinner_tab',
                        read: 'Check menu'
                    },
                    {
                        title: 'Laundry',
                        description: 'Kyiv Hostel is a modern capsule hotel in Kyiv. Our hostel is located in the Nivki microdistrict',
                        info: 'Number of machines',
                        infoDetails: ['20 pcs'],
                        link: 'washing',
                        imgClass: 'washing_tab',
                        read: 'Read more'
                    },
                    {
                        title: 'Recreation area',
                        description: 'Kyiv Hostel is a modern capsule hotel in Kyiv. Our hostel is located in the Nivki microdistrict',
                        info: 'Number of sofas',
                        infoDetails: ['40 pcs'],
                        link: 'relax',
                        imgClass: 'relax_tab',
                        read: 'Read more'
                    }
                ]
            },
            home_benefits: {
                title: 'Our advantages',
                list: [{ title: 'Large square', desc: 'The building is designed as a hotel' }, { title: 'Breakfasts, Lunches, Dinners', desc: 'All-day cafe-restaurant' }, { title: 'Price balance', desc: ' We offer solutions that will satisfy everyone' }, { title: 'Leaders in the field', desc: 'Modern solutions for needs' }, { title: 'Space for living', desc: 'Get everything you need for a comfortable stay' }, { title: 'IT component', desc: 'Convenient workplaces and high-speed Internet' },]
            },
            partners: 'Our partners',
            gallery_home: { title: 'Gallery', desc: 'You can find more photos in our Instagram page' },
            coworking: {
                title: 'Coworking',
                faq: {
                    title: 'We offer much more than solutions to these problems',
                    list: [{
                        title: 'Comfortable bedrooms',
                        p: 'The guest of the hostel pays, first of all, for a bed. We believe that it should be really high quality and comfortable. KYIV HOSTEL provides the client with an innovative, reliable bed of its own design with a depth of 110 cm. Comfortable orthopedic mattress without springs (on foam) The maid is responsible for preparing and changing the bed.',
                        ul: ['Pillow filled with polyester balls', 'Waterproof mattress topper',
                            'Comfortable orthopedic mattress without springs', 'Satin blanket']
                    },
                    {
                        title: 'The maid is responsible for preparing and changing the bed',
                        p: 'The guest of the hostel pays, first of all, for a bed. We believe that it should be really high quality and comfortable. KYIV HOSTEL provides the client with an innovative, reliable bed of its own design with a depth of 110 cm. Comfortable orthopedic mattress without springs (on foam) The maid is responsible for preparing and changing the bed.',
                        ul: ['Pillow filled with polyester balls', 'Waterproof mattress topper',
                            'Comfortable orthopedic mattress without springs', 'Satin blanket']
                    },
                    {
                        title: 'We believe that it should be really high quality',
                        p: 'The guest of the hostel pays, first of all, for a bed. We believe that it should be really high quality and comfortable. KYIV HOSTEL provides the client with an innovative, reliable bed of its own design with a depth of 110 cm. Comfortable orthopedic mattress without springs (on foam) The maid is responsible for preparing and changing the bed.',
                        ul: ['Pillow filled with polyester balls', 'Waterproof mattress topper',
                            'Comfortable orthopedic mattress without springs', 'Satin blanket']
                    },
                    {
                        title: 'Comfortable bedrooms',
                        p: 'The guest of the hostel pays, first of all, for a bed. We believe that it should be really high quality and comfortable. KYIV HOSTEL provides the client with an innovative, reliable bed of its own design with a depth of 110 cm. Comfortable orthopedic mattress without springs (on foam) The maid is responsible for preparing and changing the bed.',
                        ul: ['Pillow filled with polyester balls', 'Waterproof mattress topper',
                            'Comfortable orthopedic mattress without springs', 'Satin blanket']
                    },
                    {
                        title: 'The maid is responsible for preparing and changing the bed',
                        p: 'The guest of the hostel pays, first of all, for a bed. We believe that it should be really high quality and comfortable. KYIV HOSTEL provides the client with an innovative, reliable bed of its own design with a depth of 110 cm. Comfortable orthopedic mattress without springs (on foam) The maid is responsible for preparing and changing the bed.',
                        ul: ['Pillow filled with polyester balls', 'Waterproof mattress topper',
                            'Comfortable orthopedic mattress without springs', 'Satin blanket']
                    },
                    {
                        title: 'We believe that it should be really high quality',
                        p: 'The guest of the hostel pays, first of all, for a bed. We believe that it should be really high quality and comfortable. KYIV HOSTEL provides the client with an innovative, reliable bed of its own design with a depth of 110 cm. Comfortable orthopedic mattress without springs (on foam) The maid is responsible for preparing and changing the bed.',
                        ul: ['Pillow filled with polyester balls', 'Waterproof mattress topper',
                            'Comfortable orthopedic mattress without springs', 'Satin blanket']
                    },
                    ]
                }
            }
        },
        aboutPage: {
            head: {
                title: 'About us'
            },
            concept: {
                title: 'Concept',
                inner_title: 'KYIV Hostel is',
                desc: 'an innovative hostel that breaks the stereotypes that have developed over the years about the level of quality, comfort and service in Ukrainian places of temporary residence of this format. The idea of developing this project arose as a reaction to the current state of most hostels in Kyiv and, even more so, in other cities of our country. We have created a hostel of a new generation, behind which is the future!',
                benefits: ['Complex service', 'Quality and availability', 'Comfort and focus on the guest']
            },
            pain: {
                title: "Pains of regular hostel guests",
                list: [{ title: 'Insufficient zoning', desc: 'Lack of smoking area and separate premises for work and business meetings; cramped lounge area.' }, { title: 'Sleep quality not satisfactory', desc: 'Uncomfortable and too small beds with cheap mattresses and pillows, poor quality bed linen' }, { title: 'Unable to wash things', desc: ' Uncomfortable process of self-washing (queue, waiting). Rapid failure of washing equipment' }, { title: 'Lack of eating area', desc: 'Self-cooking, cramped kitchen, shared dishes, unsanitary, smell in refrigerator' }]
            },
            faq: {
                title: 'We suggest more richly, lower version of these problems ',
                list: [{
                    title: 'Comfortable bedrooms',
                    img: '1',
                    p: 'The guest of the hostel pays, first of all, for a bed. We believe that it should be really high quality and comfortable. KYIV HOSTEL provides the client with an innovative, reliable bed of its own design with a depth of 110 cm. Comfortable orthopedic mattress without springs (on foam) The maid is responsible for preparing and changing the bed.',
                    ul: ['Pillow filled with polyester balls', 'Waterproof mattress topper',
                        'Comfortable orthopedic mattress without springs', 'Satin blanket']
                },
                {
                    title: 'The maid is responsible for preparing and changing the bed',
                    img: '2',
                    p: 'The guest of the hostel pays, first of all, for a bed. We believe that it should be really high quality and comfortable. KYIV HOSTEL provides the client with an innovative, reliable bed of its own design with a depth of 110 cm. Comfortable orthopedic mattress without springs (on foam) The maid is responsible for preparing and changing the bed.',
                    ul: ['Pillow filled with polyester balls', 'Waterproof mattress topper',
                        'Comfortable orthopedic mattress without springs', 'Satin blanket']
                },
                {
                    title: 'We believe that it should be really high quality',
                    img: '3',
                    p: 'The guest of the hostel pays, first of all, for a bed. We believe that it should be really high quality and comfortable. KYIV HOSTEL provides the client with an innovative, reliable bed of its own design with a depth of 110 cm. Comfortable orthopedic mattress without springs (on foam) The maid is responsible for preparing and changing the bed.',
                    ul: ['Pillow filled with polyester balls', 'Waterproof mattress topper',
                        'Comfortable orthopedic mattress without springs', 'Satin blanket']
                },
                ]
            }
        },
        capsulePage: {
            head: {
                name: 'Capsule',
                title: 'in 8 Bed Room',
                desc: 'Kyiv Hostel is a modern capsule hotel in Kyiv. Our hotel is located in the Nivki microdistrict',
                benefits: ['Check-in 24/7', 'Food Zone', 'Coworking'],
                priceFrom: 'Price from:',
                currency: 'uah',
                price: '250',
            },
            description: {
                title: 'Description of the capsule',
                name: 'Sleeping place',
                stuff: ['Towels', 'Personal lighting', 'Curtain', 'Storage for things', 'Charging place'],
                more_title: 'More about the number',
                more_text: ['Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi. Nulla Lorem mollit cupidatat irure.', 'Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi. Nulla Lorem mollit cupidatat irure.'],
                benefits: [{ title: 'Підготовлене місце', desc: 'Застелене ліжко з білизною' }, { title: 'Комфорт і якісний сон', desc: 'Матраци без пружин, шумоізоляція' }]
            },
            gallery: { title: 'Gallery', desc: 'You can find more photos in our Instagram page' },
        },
        roomPage: {
            head: {
                name: 'Room',
                title: 'with a toilet',
                desc: 'Kyiv Hostel is a modern capsule hotel in Kyiv. Our hotel is located in the Nivki microdistrict',
                benefits: ['Check-in 24/7', 'Food Zone', 'Coworking'],
                priceFrom: 'Price from:',
                currency: 'uah',
                price: '250',
            },
            description: {
                title: 'Description of the capsule',
                name: 'Sleeping place',
                stuff: ['Towels', 'Personal lighting', 'Curtain', 'Storage for things', 'Charging place'],
                more_title: 'More about the number',
                more_text: ['Просторий і затишний двомісний номер, обладнаний двома ліжками, комодом для зберігання речей, великим дзеркалом. Комод відкривається індивідуальним ключем - 100% безпека і охорона ваших речей.','Широке спальне місце (110 см) оснащене комфортним ортопедичним матрацом на піні, водонепроникним наматрацником, сатиновою ковдрою, а також подушкою з поліефірних кульок.','Туалет і душ розташовані поруч, на поверсі.'],
                benefits: [{ title: 'Підготовлене місце', desc: 'Застелене ліжко з білизною' }, { title: 'Комфорт і якісний сон', desc: 'Матраци без пружин, шумоізоляція' }],
                variant: {title: 'Room with toilet', link: 'rooms'}
            },
            gallery: { title: 'Gallery', desc: 'You can find more photos in our Instagram page' },
        },
        roomPageVip: {
            head: {
                name: 'Room',
                title: 'with a toilet',
                desc: 'Kyiv Hostel is a modern capsule hotel in Kyiv. Our hotel is located in the Nivki microdistrict',
                benefits: ['Check-in 24/7', 'Food Zone', 'Coworking'],
                priceFrom: 'Price from:',
                currency: 'uah',
                price: '250',
            },
            description: {
                title: 'Description of the capsule',
                name: 'Sleeping place',
                stuff: ['Towels', 'Personal lighting', 'Curtain', 'Storage for things', 'Charging place'],
                more_title: 'More about the number',
                more_text: ['Просторий і затишний двомісний номер, обладнаний двома ліжками, комодом для зберігання речей, великим дзеркалом. Комод відкривається індивідуальним ключем - 100% безпека і охорона ваших речей.','Широке спальне місце (110 см) оснащене комфортним ортопедичним матрацом на піні, водонепроникним наматрацником, сатиновою ковдрою, а також подушкою з поліефірних кульок.','Туалет і душ розташовані поруч, на поверсі.'],
                benefits: [{ title: 'Підготовлене місце', desc: 'Застелене ліжко з білизною' }, { title: 'Комфорт і якісний сон', desc: 'Матраци без пружин, шумоізоляція' }],
                variant: {title: 'Room without toilet', link: 'roomsregular'}
            },
            gallery: { title: 'Gallery', desc: 'You can find more photos in our Instagram page' },
        },
        contactPage: {
            head: {
                title: 'Contacts'
            },
            messengers: 'Our messengers:',
            transport: 'How to get to hotel:',
            bus: 'Bus',
            metro: 'Underground',
            station: 'st. Nyvky',
            trolleybus: 'Trolleybus',
            route: 'Route taxi'
        },
        coworkingPage: {
            head: {
                title: 'Coworking',
                desc: 'Kyiv Hostel is a modern capsule hotel in Kyiv. Our hotel is located in the Nivki microdistrict',
                benefits: ['Check-in 24/7', 'Food Zone', 'Coworking'],
                priceFrom: 'Price from:',
                currency: 'uah',
                price: '250',
            },
            description: {
                title: 'Coworking',
                name: 'Working place',
                stuff: ['Table', 'Personal lighting', 'Chair', 'Storage for things', 'Charging place'],
                more_title: 'More about coworking',
                more_text: 'Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi. Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. Voluptate exercitation incididunt aliquip deserunt reprehenderit elit laborum.',
                benefits: [{ title: 'Світло для роботи', desc: 'Безперебійне електропостачання' }, { title: 'Повне зосередження', desc: 'Тиха робоча атмосфера' }],
            },
            gallery: { title: 'Gallery', desc: 'You can find more photos in our Instagram page' },
        },
        missingPage: {
            title: 'Page not found'
        },
        franchisePage: {
            head: {
                title: 'Franchise'
            },
            text: 'For issues of cooperation, please contact:'
        }

    }

}