import './style.scss';

export default function Gallery({ name }) {
  return (
    <div className={`gallery ${name}`}>
      <div className="gallery_item"></div>
      <div className="gallery_item"></div>
      <div className="gallery_item"></div>
      <div className="gallery_item"></div>
    </div>
  );
}
