import { useContext, useEffect } from 'react';
import DataContext from '../../context/DataContext';
import './style.scss';

export default function FormDuplicate({ name = '' }) {
  const { lang, setLang, setLinkEN, setLinkUA } = useContext(DataContext);
  // let showForm;

  function showForm(w) {
    var q = [
      ['setContext', 'TL-INT-kievhostel_space.new', 'uk'],
      [
        'embed',
        'search-form',
        {
          container: 'tl-search-form-d-ua',
        },
      ],
    ];
    var h = [
      'ua-ibe.tlintegration-eu.com',
      'ibe.tlintegration-eu.com',
      'ibe.tlintegrationfb-eu.com',
    ];
    var t = (w.travelline = w.travelline || {}),
      ti = (t.integration = t.integration || {});
    ti.__cq = ti.__cq ? ti.__cq.concat(q) : q;
    if (!ti.__loader) {
      ti.__loader = true;
      var d = w.document,
        c =
          d.getElementsByTagName('head')[0] ||
          d.getElementsByTagName('body')[0];
      function e(s, f) {
        return function () {
          //   w.TL || (c.removeChild(s), f());
        };
      }
      (function l(h) {
        if (0 === h.length) return;
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = !0;
        s.src = 'https://' + h[0] + '/integration/loader.js';
        s.onerror = s.onload = e(s, function () {
          l(h.slice(1, h.length));
        });
        c.appendChild(s);
      })(h);
    }
  }
  function showFormEn(w) {
    var q = [
      ['setContext', 'TL-INT-kievhostel_space.new', 'en'],
      [
        'embed',
        'search-form',
        {
          container: 'tl-search-form-d-en',
        },
      ],
    ];
    var h = [
      'ua-ibe.tlintegration-eu.com',
      'ibe.tlintegration-eu.com',
      'ibe.tlintegrationfb-eu.com',
    ];
    var t = (w.travelline = w.travelline || {}),
      ti = (t.integration = t.integration || {});
    ti.__cq = ti.__cq ? ti.__cq.concat(q) : q;
    if (!ti.__loader) {
      ti.__loader = true;
      var d = w.document,
        c =
          d.getElementsByTagName('head')[0] ||
          d.getElementsByTagName('body')[0];
      function e(s, f) {
        return function () {
          // w.TL || (c.removeChild(s), f());
        };
      }
      (function l(h) {
        if (0 === h.length) return;
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = !0;
        s.src = 'https://' + h[0] + '/integration/loader.js';
        s.onerror = s.onload = e(s, function () {
          l(h.slice(1, h.length));
        });
        c.appendChild(s);
      })(h);
    }
  }
  useEffect(() => {
    showForm(window);
    showFormEn(window);
  }, []);
  return (
    <div className={`order_wrapper ${name}`}>
      <div id="block-search">
        {lang==='ua'?<div id="tl-search-form-d-ua" className="tl-container"></div>:<div id="tl-search-form-d-en" className="tl-container"></div>}
      </div>
    </div>
  );
}
